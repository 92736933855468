.my-jumbo {
    background-color: rgb(66, 66, 66);
    color: white;
    margin: 0;
    border-radius: 0px;
    font-family: 'Sofia', sans-serif;
}
.my-jumbo2 {
    background-color: rgb(112, 110, 110);
    color: white;
    margin: 0;
    border-radius: 0px;
    font-family: 'Sofia', sans-serif;
    display: flex;
    text-align: center;
    flex-wrap: wrap;
    justify-content: center;
}

.logo-big {
    width: 200px;
    margin-top: 40px;
}
.mission {
   width: 800px;
}

.instructions {
    display: flex;
    flex-wrap: wrap;
    padding-top: 30px;
    padding-bottom: 30px;
    justify-content: center;
}

.jumbo-title {
    font-size: 30px;
    padding-top: 20px;
}

.step {
    width: 250px;
    display: flex;
    background-color: #E6E6E8;
    color: black;
    margin: 10px;
    padding: 10px;
    border-radius: 10px;
}

.step-img {
    color: white;
    width: 100px;
    height: 80px;
    padding-right: 10px;
}
@media screen and (max-width: $small) {
    .step-img {
        width: 50px;
        height: 40px;
    }
}