footer {
    background-color: rgb(41, 41, 41);
    color: white;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    min-height: 100%;

    /* Equal to height of footer */
    /* But also accounting for potential margin-bottom of last child */
    margin-bottom: -50px;
}

.new-footer-link {
    color: white;
    opacity: .5;
}

.footer-items {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}

.item {
    margin: 15px;
    font-style: italic;
}

.statement {
    margin-left: 80px;
    margin-right: 80px;
}

.item-social {
    font-size: 20px;
    font-weight: 900;
    text-transform: uppercase;
}

.input-llc {
    color: white;
    opacity: .5;
}
.input-llc:hover {
    color: white;
    opacity: .3;
}

.foot-img {
    margin: 8px;
}