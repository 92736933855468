.calendar {
    box-sizing: border-box;
    font-size: 12px;
    max-width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0px;
  }
  
  .calendar .header {
    background-color: var(--light-blue);
    text-align: center;
    min-height: 2rem;
    line-height: 2rem;
    color: var(--black);
    font-family: var(--font-book);
    font-weight: 700;
    font-size: 20px;
    display: flex;
  }
  
  .calendar .day-names {
    display: flex;
    flex-wrap: wrap;
    max-width: 1000px;
    margin: 0 auto;
    align-items: center;
  }
  
  .calendar .body {
    border: 1px solid var(--light-blue);
    font-family: 'Sofia', sans-serif;
  }
  
  .calendar .header .previous {
    flex: 1;
    text-align: left;
    margin-left: 1rem;
  }
  
  .calendar .header .next {
    flex: 1;
    text-align: right;
    margin-right: 1rem;
  }
  
  .calendar .week {
    /* background-color: white; */
    width: calc(100% / 7);
    height: 44px;
    line-height: 44px;
    text-align: center;
    text-transform: uppercase;
    color: var(--black);
    font-weight: 400;
  }
  .calendar .week div {
    width: 100%;
  }
  
  .calendar .day {
    position: relative;
    width: calc(100% / 7);
    height: 64px;
    display: inline-block;
    background-color:#21d176;
    color: white;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    z-index: 1;
    text-align: center;
    border: 1px solid #eee ;
    box-shadow: 0 2px 3px #ccc;
  }

  
  .calendar .day div {
    height: 64px;
    position: relative;
    color: var(--black);
    z-index: 100;
    line-height: 64px;
  }
  @media screen and (max-width: 500px) {
    .calendar .day {
      height: 34px;
    }
    .calendar .day div {
      height: 34px;
      line-height: 34px;
    }
  }

  .calendar .day div.before {
    color: var(--grey);
  }
  
  .calendar .day div.selected {
    background-color: var(--red) !important;
    color: var(--white);
    font-weight: bold;
  }
  
  .calendar .day div.today {
    background-color: var(--grey);
  }






  